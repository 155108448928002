<template>
    <AsyncLoader :loaded="isTicketsSearchLoaded">
        <div class="container-fluid px-xl-5 mt-3">
            <div class="row" v-if="layout === 'table'">
                <div class="col-xl-8 offset-xl-2">
                    <Table
                        :columns="['id', 'name', 'state', 'last_activity', 'assigned_to',
                                    ...(getEventSlug==='all'?['event']:[])]"
                        :items="getTicketsSearchResults.map(formatTicket)"
                        :keyName="'id'"
                    >
                        <template v-slot:actions="{item}">
                            <div class="btn-group">
                                <router-link :to="{name: 'ticket', params: {id: item.id}}" class="btn btn-primary"
                                             title="view">
                                    <font-awesome-icon icon="eye"/>
                                    View
                                </router-link>
                            </div>
                        </template>
                    </Table>
                </div>
            </div>
            <CollapsableCards v-if="layout === 'tasks'" :items="getTicketsSearchResults"
                              :columns="['id', 'name', 'last_activity', 'assigned_to',
                                        ...(getEventSlug==='all'?['event']:[])]"
                              :keyName="'state'" :sections="['pending_new', 'pending_open','pending_shipping',
                          'pending_physical_confirmation','pending_return','pending_postponed','pending_suspected_spam'].map(stateInfo)">
                <template #section_header="{index, section, count}">
                    {{ section.text }} <span class="badge badge-light ml-1">{{ count }}</span>
                </template>
                <template #section_body="{item}">
                    <tr>
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.last_activity }}</td>
                        <td>{{ item.assigned_to }}</td>
                        <td v-if="getEventSlug==='all'">{{ item.event }}</td>
                        <td>
                            <div class="btn-group">
                                <router-link :to="{name: 'ticket', params: {id: item.id}}" class="btn btn-primary"
                                             title="view">
                                    <font-awesome-icon icon="eye"/>
                                    View
                                </router-link>
                            </div>
                        </td>
                    </tr>
                </template>
            </CollapsableCards>
        </div>
    </AsyncLoader>
</template>

<script>
import Cards from '@/components/Cards';
import {mapActions, mapGetters, mapState} from 'vuex';
import Table from '@/components/Table';
import CollapsableCards from "@/components/CollapsableCards.vue";
import AsyncLoader from "@/components/AsyncLoader.vue";
import router from "@/router";

export default {
    name: 'TicketSearch',
    components: {AsyncLoader, Table, Cards, CollapsableCards},
    computed: {
        ...mapGetters(['getTicketsSearchResults', 'isTicketsSearchLoaded', 'stateInfo', 'getEventSlug', 'layout', 'searchQuery']),
    },
    methods: {
        ...mapActions(['searchEventTickets', 'fetchTicketStates', 'scheduleAfterInit']),
        gotoDetail(ticket) {
            router.push({name: 'ticket', params: {id: ticket.id}});
        },
        formatTicket(ticket) {
            return {
                id: ticket.id,
                name: ticket.name,
                state: this.stateInfo(ticket.state).text,
                stateColor: this.stateInfo(ticket.state).color,
                last_activity: ticket.last_activity,
                assigned_to: ticket.assigned_to,
                event: ticket.event
            };
        }
    },
    watch: {
        searchQuery() {
            this.scheduleAfterInit(() => [this.searchEventTickets(this.searchQuery)]);
        },
        getEventSlug() {
            this.scheduleAfterInit(() => [this.searchEventTickets(this.searchQuery)]);
        }
    },
    mounted() {
        this.scheduleAfterInit(() => [this.fetchTicketStates(), this.searchEventTickets(this.searchQuery)]);
    }
};
</script>

<style scoped>

</style>